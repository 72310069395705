import React, {
  FC,
  LegacyRef,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  Ref,
  RegisterOptions,
  SubmitHandler
} from 'react-hook-form';
import { IReferralFormData } from '~/api/referral';
import { FromSection } from './FormSection';
import classes from './ReferralPageV1.module.scss';
import Spacer from '~/components/Spacer/Spacer';
import cobuApp from '~/utils/images/cobuApp.png';
import { Rating } from 'react-simple-star-rating';
import { FormInputConfig } from '~/types/residentManagement';
import { FormField } from '~/components/FormField';
import MultiSelectCheckboxList from '~/components/MultiSelectCheckBoxList/MultiSelectCheckBoxList';
import { IMultiSelectOptionList } from '~/helpers/interfaces';
import { AvatarColors, landingPageFormConfigV1 } from './constants';
import { isUndefined } from 'lodash';
import { ReactComponent as ReferralShare } from '~/utils/images/referralShare.svg';
import { convertToBold, toTitleCase, toUpperCase } from '~/utils/stringUtils';
import Spinner from '~/components/Spinner/Spinner';
import { ModalFloatLoader } from '~/components/ModalFloatLoader/ModalFloatLoader';
import { ReactComponent as CalenderIcon } from '~/utils/images/calendar.svg';
import { ReferralIntroMarkups } from '../../constants';
import _ from 'lodash';
import Logo from '~/utils/images/logo.svg';
import { defaultProfilePicture } from '~/helpers/constants';

interface IProps {
  onSubmitForm: (e?: React.BaseSyntheticEvent) => Promise<void>;
  referraFormlData: IReferralFormData;
  errors: FieldErrors<any>;
  control: Control<any>;
  register: (rules?: RegisterOptions) => (ref: Ref | null) => void;
  emailConfirmationOption: IMultiSelectOptionList[];
  onEmailConfirmation: (value: string, isSelected: boolean) => void;
  showThankYou?: boolean;
  isAppReferral?: boolean;
  isPreviewOnly?: boolean;
  loading?: boolean;
}

export const ReferralPageV1: FC<IProps> = ({
  onSubmitForm,
  referraFormlData,
  errors,
  register,
  control,
  emailConfirmationOption,
  onEmailConfirmation,
  showThankYou,
  isAppReferral,
  isPreviewOnly,
  loading
}) => {
  const [showModal, setShowModal] = useState(false);
  const thankYouSectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (showThankYou && thankYouSectionRef.current) {
      setShowModal(false);

      setTimeout(() => {
        thankYouSectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [showThankYou]);

  useEffect(() => {
    if (!isPreviewOnly) {
      setTimeout(() => {
        setShowModal(true);
      }, 2000);
    }
  }, []);

  const backgroundColor =
    referraFormlData?.building?.details?.logoBackgroundColor;

  const generateField = (
    fieldConfig: FormInputConfig,
    errorColor = 'white'
  ) => {
    return (
      <div key={fieldConfig.id}>
        <div className={classes.inputItem}>
          <FormField
            fieldConfig={{
              ...fieldConfig,
              styles: {
                ...fieldConfig.styles,
                errorStyles: { color: errorColor }
              }
            }}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const roomAvailableClassification: null | Record<
    number,
    { name: string; available: number; price: number | undefined }
  > = useMemo(() => {
    if (referraFormlData?.helloData?.building_availability) {
      const classification: Record<
        number,
        { name: string; available: number; price: number | undefined }
      > = {
        0: { name: "studio", available: 0, price: undefined },
        1: { name: "1 bedroom", available: 0, price: undefined },
        2: { name: "2 bedroom", available: 0, price: undefined },
        3: { name: "3+ bedroom", available: 0, price: undefined },
      };
  
      referraFormlData.helloData.building_availability
        .filter((building) => building.exit_market === null)
        .forEach((availableBuilding) => {
          const { bed, min_price, price } = availableBuilding;
  
          const minPrice = Math.max(min_price, price);
  
          if (classification[bed] && bed < 3) {
            classification[bed].available += 1;
            classification[bed].price = isUndefined(classification[bed].price)
              ? minPrice
              : Math.min(classification[bed].price as number, minPrice);
          } else if (bed > 2 && bed < 1000) {
            classification[3].available += 1;
            classification[3].price = isUndefined(classification[3].price)
              ? minPrice
              : Math.min(classification[3].price as number, minPrice);
          }
        });
      return classification;
    }
    return null;
  }, [referraFormlData?.helloData?.building_availability]);

  const isRoomAvailable = roomAvailableClassification
    ? !_.every(
      roomAvailableClassification,
      (property) => property.available === 0
    )
    : false;

  const renderRoomAvailability = ({
    name,
    available,
    price
  }: {
    name: string;
    available: number;
    price: undefined | number;
  }) => {
    if (!available || isUndefined(price)) {
      return null;
    }

    return (
      <div
        style={{
          textAlign: 'start',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <span
          className={`heading bold ${classes.noWordSpacing}`}
          style={{
            color: '#FFFFFF',
            textAlign: 'center'
          }}
        >
          {name.toUpperCase()}
        </span>
        <div
          style={{
            margin: '0 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <span
            className={`heading bold ${classes.noWordSpacing}`}
            style={{
              color: '#FFA5D8',
              textAlign: 'center'
            }}
          >
            {`STARTING @ $${price.toLocaleString()}`}
          </span>
          <span
            className={`heading bold ${classes.noWordSpacing}`}
            style={{
              color: '#FFA5D8',
              textAlign: 'center'
            }}
          >
            {`${available} AVAILABLE`}
          </span>
        </div>
      </div>
    );
  };

  const {
    landingPageTitleAction: defaultLandingPageTitleAction,
    popupTitle: defaultPopupTitle,
    popupDescription: defaultPopupDescription,
    landingPageCongratsDescription: defaultLandingPageCongratsDescription,
    employeeLandingPageTitleAction,
    employeePopupTitle,
    employeePopupDescription,
    employeeLandingPagecongratsDescription,
    landingPageLocationBlurb,
    landingPageFormButton,
    landingPageCongratsTitle,
    landingPageCongratsNext,
    details
  } = referraFormlData.referralOffer;

  const landingPageTitleAction = referraFormlData?.referredUser?.isEmployee
    ? employeeLandingPageTitleAction || defaultLandingPageTitleAction
    : defaultLandingPageTitleAction;

  const popupTitle = referraFormlData?.referredUser?.isEmployee
    ? employeePopupTitle || defaultPopupTitle
    : defaultPopupTitle;

  const popupDescription = referraFormlData?.referredUser?.isEmployee
    ? employeePopupDescription || defaultPopupDescription
    : defaultPopupDescription;

  const landingPageCongratsDescription = referraFormlData?.referredUser
    ?.isEmployee
    ? employeeLandingPagecongratsDescription ||
    defaultLandingPageCongratsDescription
    : defaultLandingPageCongratsDescription;

  const renderReferralForm = (
    headingColor = '#FFFFFF',
    emailConfirmationColor = 'white',
    errorColor = 'white',
    privacyPolicyColor = 'white',
    combineOfferColor = 'white',
    showHeader = false
  ) => {
    const Container = isPreviewOnly ? 'div' : 'form';

    return (
      <>
        {showHeader && popupTitle && (
          <>
            <div className='heading3 bold text-align-center'>{popupTitle}</div>
            <Spacer height={15} />
          </>
        )}
        {popupDescription && (
          <div
            className='heading4 semiBold text-align-center'
            style={{ textAlign: 'center', color: headingColor }}
          >
            {popupDescription}
          </div>
        )}
        <Spacer height={15} />
        <Container id='form-container' onSubmit={onSubmitForm}>
          <div className={classes.formFields}>
            {landingPageFormConfigV1.map((field) =>
              generateField(field, errorColor)
            )}
          </div>
          {!isAppReferral && (
            <>
              <Spacer height={10} />
              <Controller
                name='confirmationEmail'
                defaultValue={false}
                rules={{ required: false }}
                render={({ onChange }) => (
                  <MultiSelectCheckboxList
                    options={[
                      {
                        ...emailConfirmationOption[0],
                        containerStyle: {
                          ...emailConfirmationOption[0].containerStyle,
                          color: emailConfirmationColor
                        }
                      }
                    ]}
                    setSelectedOption={(value, isSelected) => {
                      onChange(isSelected);

                      onEmailConfirmation(value, isSelected);
                    }}
                    checkTintColor='#4F4F4F'
                    tickColor='#FFFFFF'
                    enableLabelClickToSelect
                  />
                )}
                control={control}
              />
            </>
          )}
          <Spacer height={15} />
          <div
            className={`${classes['combined-bonus']} sub-heading semiBold`}
            style={{ color: combineOfferColor }}
          >
            The Resident Referral bonus cannot be combined with the Preferred
            Employer bonus. Only one referral bonus will be paid per eligible
            lease.
          </div>
          <Spacer height={15} />
          <a
            target='_blank'
            href='https://www.livecobu.com/privacy-policy'
            style={{ textDecoration: 'underline', color: privacyPolicyColor }}
          >
            Privacy Policy
          </a>
          <Spacer height={15} />
          <button
            style={{
              backgroundColor: '#14BBD1',
              color: 'white',
              borderRadius: '30px',
              minHeight: '45px',
              width: '100%'
            }}
            className='btn-standard semiBold heading4'
            type='submit'
          >
            {toTitleCase(landingPageFormButton || 'Claim Rent Credit!')}
          </button>
        </Container>
      </>
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const renderMultilineColorString = (
    paragraph?: string,
    twoColorCombo = ['black', 'black'],
    isUpperCase = true
  ) => {
    if (!paragraph) {
      return null;
    }

    const stringSplit = paragraph.split('\n');

    if (stringSplit.length === 1) {
      return (
        <span
          className={`heading bold ${classes.noWordSpacing}`}
          style={{
            color: twoColorCombo[0],
            textAlign: 'center'
          }}
        >
          {isUpperCase ? toUpperCase(paragraph) : paragraph}
        </span>
      );
    }

    return (
      <span
        className={`heading bold ${classes.noWordSpacing}`}
        style={{
          color: twoColorCombo[0],
          textAlign: 'center'
        }}
      >
        {isUpperCase ? toUpperCase(stringSplit[0]) : stringSplit[0]}
        {stringSplit.slice(1).map((nextLine, index) => {
          const isEven = index % 2 === 0;

          return (
            <>
              <br />
              <span
                className={`heading bold ${classes.noWordSpacing}`}
                style={{
                  color: isEven ? twoColorCombo[1] : twoColorCombo[0],
                  textAlign: 'center'
                }}
              >
                {isUpperCase ? toUpperCase(nextLine) : nextLine}
              </span>
            </>
          );
        })}
      </span>
    );
  };

  // if (showThankYou) {
  //   return (
  //     <div className={`${classes.container}`}>
  //       <div className={`${classes.content} ${classes.thankyouContent}`}>
  //         <FromSection
  //           bgColor='#DD245C'
  //           showBottomBorder={false}
  //           showTopBorder={false}
  //         >
  //           <div className='text-align-center'>
  //             {!!landingPageCongratsTitle && (
  //               <>
  //                 <div className='title bold' style={{ color: '#FFFFFF' }}>
  //                   {toUpperCase(landingPageCongratsTitle)}
  //                 </div>
  //                 <Spacer height={20} />
  //               </>
  //             )}
  //             {!!landingPageCongratsDescription && (
  //               <>
  //                 <div className='heading3' style={{ color: '#FFFFFF' }}>
  //                   {landingPageCongratsDescription}
  //                 </div>
  //                 <Spacer height={30} />
  //               </>
  //             )}
  //             {!!landingPageCongratsNext && (
  //               <div className='heading3' style={{ color: '#FFFFFF' }}>
  //                 {landingPageCongratsNext}
  //               </div>
  //             )}
  //           </div>
  //           {!!referraFormlData?.building?.propertyWebsiteURL && (
  //             <>
  //               <Spacer height={40} />
  //               <div
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'row',
  //                   gap: '5px 5px',
  //                   justifyContent: 'center',
  //                   alignItems: 'center'
  //                 }}
  //               >
  //                 <CalenderIcon />
  //                 <a
  //                   href={referraFormlData?.building.propertyWebsiteURL}
  //                   style={{ textDecoration: 'underline', color: 'white' }}
  //                   className='heading3'
  //                 >
  //                   Schedule a Tour
  //                 </a>
  //               </div>
  //             </>
  //           )}
  //         </FromSection>
  //       </div>
  //     </div>
  //   );
  // }


  const renderRow = (
    indConcession: string
  ) => {
    return (
      <div>
        <span
          className={`heading bold ${classes.noWordSpacing}`}
          style={{
            color: '#504a8a',
            textAlign: 'center'
          }}
        >🎉 {indConcession}
        </span>
      </div>
    )
  };

  const renderThankyouSection = () => {
    return (
      <div ref={thankYouSectionRef}>
        <FromSection bgColor='#DD245C'>
          <div className='text-align-center'>
            {!!landingPageCongratsTitle && (
              <>
                <div className='title bold' style={{ color: '#FFFFFF' }}>
                  {toUpperCase(landingPageCongratsTitle)}
                </div>
                <Spacer height={20} />
              </>
            )}
            {!!landingPageCongratsDescription && (
              <>
                <div className='heading3' style={{ color: '#FFFFFF' }}>
                  {landingPageCongratsDescription}
                </div>
                <Spacer height={30} />
              </>
            )}
            {!!landingPageCongratsNext && (
              <div className='heading3' style={{ color: '#FFFFFF' }}>
                {landingPageCongratsNext}
              </div>
            )}
          </div>
          {!!referraFormlData?.building?.propertyWebsiteURL && (
            <>
              <Spacer height={40} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px 5px',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CalenderIcon />
                <a
                  href={referraFormlData?.building.propertyWebsiteURL}
                  style={{ textDecoration: 'underline', color: 'white' }}
                  className='heading3'
                >
                  Schedule a Tour
                </a>
              </div>
            </>
          )}
        </FromSection>
      </div>
    );
  };

  const referrerName = `${referraFormlData?.referredUser.firstName ?? ''} ${referraFormlData?.referredUser.lastName ?? ''
    }`;

  const showProfileImage =
    !!referraFormlData?.userCustomization?.details?.includeProfileImage;

  const myDetails = referraFormlData?.helloData?.details;


  let arePetsAllowed = false;
  let areDogsAllowed = false;
  let areCatsAllowed = false;
  let isCommunityDogPark = false;

  myDetails?.building_amenities.forEach((element) => {
    if (element == "pets_allowed") arePetsAllowed = true;
    if (element == "dogs_allowed") areDogsAllowed = true;
    if (element == "cats_allowed") areCatsAllowed = true;
    if (element == "community_dog_park") isCommunityDogPark = true;
  });

  // Cat Details
  let catDeposit = (referraFormlData?.helloData?.details?.cats_deposit !== null) ? referraFormlData?.helloData?.details?.cats_deposit : 0;
  let catMonthlyRent = (referraFormlData?.helloData?.details?.cats_monthly_rent !== null) ? referraFormlData?.helloData?.details?.cats_monthly_rent : 0;
  let catOneTimeFee = (referraFormlData?.helloData?.details?.cats_one_time_fee !== null) ? referraFormlData?.helloData?.details?.cats_monthly_rent : 0;

  // Dog Details
  let dogDeposit = (referraFormlData?.helloData?.details?.dogs_deposit !== null) ? referraFormlData?.helloData?.details?.dogs_deposit : 0;
  let dogMonthlyRent = (referraFormlData?.helloData?.details?.dogs_monthly_rent !== null) ? referraFormlData?.helloData?.details?.dogs_monthly_rent : 0;
  let dogOneTimeFee = (referraFormlData?.helloData?.details?.dogs_one_time_fee !== null) ? referraFormlData?.helloData?.details?.dogs_one_time_fee : 0;

  let dogDetails = (dogDeposit || 0) + (dogMonthlyRent || 0) + (dogOneTimeFee || 0);
  let catDetails = (catDeposit || 0) + (catMonthlyRent || 0) + (catOneTimeFee || 0);

  let concessionHistory = myDetails?.concessions_history;
  let today = new Date(), date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  let areActiveConcessions = false;

  let arrayActiveConcessions: any[] = [];


  let counter = 0;
  concessionHistory?.forEach((indConcession) => {

    let dateStart = new Date(indConcession.from_date);
    let dateEnd = new Date(indConcession.to_date + 2);
    //dateEnd = new Date('2024-12-05T03:24:00');
    let dateToday = new Date();


    if (dateStart.getTime() <= dateToday.getTime()) {
      if (dateEnd.getTime() >= dateToday.getTime()) {
        areActiveConcessions = true;
        indConcession.id = counter;
        arrayActiveConcessions[counter] = indConcession.concessions;
      }
    }

    counter++;

  });

  const referralIntroHeader = useMemo(() => {
    if (referraFormlData?.userCustomization?.welcome_message) {
      return referraFormlData?.userCustomization?.welcome_message;
    }

    let referralHeader = referraFormlData?.referredUser?.isEmployee
      ? referraFormlData?.referralOffer?.employeeLandingPageIntroParagraph ||
      referraFormlData?.referralOffer.landingpageIntroParagraph
      : referraFormlData?.referralOffer.landingpageIntroParagraph;

    if (!referralHeader) {
      return null;
    }

    const parts = referralHeader.split(
      new RegExp(
        `(${ReferralIntroMarkups.referredBy}|${ReferralIntroMarkups.buildingName})`
      )
    );

    const replacedString: string[] = parts.reduce((prev, next) => {
      switch (next) {
        case ReferralIntroMarkups.referredBy:
          prev.push(`<strong>${referrerName}</strong>`);
          break;

        case ReferralIntroMarkups.buildingName:
          prev.push(`<strong>${referraFormlData?.building?.name}</strong>`);
          break;

        default:
          prev.push(next);
      }

      return prev;
    }, [] as string[]);

    return replacedString.join('');
  }, [referraFormlData]);

  return (
    <div className={`${classes.container}`}>
      <ModalFloatLoader loading={!!loading} />
      <div className={classes.content}>
        {landingPageTitleAction && (
          <div
            className={`${classes.hedingSection} heading bold text-align-center`}
          >
            {renderMultilineColorString(landingPageTitleAction, [
              '#FFFFFF',
              '#FFFFFF'
            ])}
          </div>
        )}
        <FromSection
          bgColor='#DD245C'
          bgImage={referraFormlData.building.image}
        >
          {referraFormlData.building.propertyLogoUrl && (
            <img
              src={referraFormlData.building.propertyLogoUrl as string}
              className={classes.headerImage}
              style={
                {
                  '--backgroundColor': backgroundColor
                } as React.CSSProperties
              }
            />
          )}
        </FromSection>
        {!!referralIntroHeader && (
          <FromSection noMarginTop bgColor='#14BBD1'>
            <div className={classes.messageWrapper}>
              {showProfileImage && (
                <>
                  {referraFormlData.referredUser.profileImage &&
                    referraFormlData.referredUser.profileImage !==
                    defaultProfilePicture ? (
                    <img
                      src={referraFormlData.referredUser.profileImage}
                      className={classes.profileImage}
                    />
                  ) : (
                    <div
                      className={classes.profileImage}
                      style={{
                        ...AvatarColors[
                        referraFormlData?.referredUser.uuid.charCodeAt(0) % 8
                        ]
                      }}
                    >
                      {referraFormlData?.referredUser?.firstName
                        .charAt(0)
                        .toUpperCase()}
                      {referraFormlData?.referredUser?.lastName
                        .charAt(0)
                        .toUpperCase()}
                    </div>
                  )}
                </>
              )}
              <div
                className='heading4 semiBold'
                dangerouslySetInnerHTML={{ __html: referralIntroHeader }}
              />
            </div>
          </FromSection>
        )}
        <FromSection
          bgColor='white'
          contentStyles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <>
            <div>
              <div style={{ textAlign: 'center' }}>
                {renderMultilineColorString(landingPageLocationBlurb, [
                  '#0C5CF7',
                  '#3AD8F4'
                ])}
              </div>
              {!!referraFormlData?.helloData?.address && (
                <>
                  <Spacer height={20} />
                  <div>
                    <span className='sub-heading bold'>Address: </span>
                    <span className='paragraph semiBold'>
                      {referraFormlData.helloData.address}
                    </span>
                  </div>
                </>
              )}
              {!!referraFormlData?.helloData?.building_phone_number && (
                <>
                  <Spacer height={10} />
                  <div>
                    <span className='sub-heading bold'>Phone: </span>
                    <span className='paragraph semiBold'>
                      {referraFormlData.helloData.building_phone_number}
                    </span>
                  </div>
                </>
              )}
              <Spacer height={20} />
              {!!referraFormlData.avgReviewRating && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <img
                        src={
                          'https://cobu-static-assets.s3.amazonaws.com/google-text.png'
                        }
                        style={{ width: 100, height: 32 }}
                      />
                      <span className='subHeading bold'>Reviews</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '0px 4px',
                        alignItems: 'center'
                      }}
                    >
                      <span className='heading bold'>
                        {Number(
                          Number(
                            referraFormlData.avgReviewRating.avgReviewRating
                          ).toFixed(1)
                        )}
                      </span>

                      <Rating
                        initialValue={Number(
                          Number(
                            referraFormlData.avgReviewRating.avgReviewRating
                          ).toFixed(1)
                        )}
                        readonly
                        size={30}
                        allowHover={false}
                        allowFraction
                      />
                      <span style={{ color: '#504a8a' }}>
                        {`(${referraFormlData.avgReviewRating.totalReviews})`}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </FromSection>

        {isRoomAvailable && (
          <FromSection bgColor='#B106CD'>
            <div style={{ textAlign: 'start', display: 'flex' }}>
              <span
                className={`heading bold ${classes.noWordSpacing}`}
                style={{
                  color: '#FFFFFF',
                  textAlign: 'center'
                }}
              >{`WHAT IS`}</span>
              <br />
              <span
                className={`heading bold ${classes.noWordSpacing}`}
                style={{
                  color: '#FFA5D8',
                  textAlign: 'center'
                }}
              >
                {'‎ AVAILABLE'}
              </span>
            </div>
            <Spacer height={20} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px 0'
              }}
            >
              {!!roomAvailableClassification &&
                Object.values(roomAvailableClassification).map(
                  (availableConfig) => renderRoomAvailability(availableConfig)
                )}
            </div>
          </FromSection>

        )}


        {arePetsAllowed && (

          <FromSection bgColor='#14BBD1'>
            <div style={{ textAlign: 'start', display: 'flex' }}>
              <span
                className={`heading bold ${classes.noWordSpacing}`}
                style={{
                  color: '#FFFFFF',
                  textAlign: 'center'
                }}
              >{`ARE MY PETS`}</span>
              <br />
              <span
                className={`heading bold ${classes.noWordSpacing}`}
                style={{
                  color: '#C4EEF3',
                  textAlign: 'center'
                }}
              >
                {'‎ ALLOWED'}
              </span>
            </div>
            <Spacer height={20} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px 0'
              }}
            >
              <div
                className='paragraph'
                style={{ color: '#FFFFFF', textAlign: 'start' }}
              >
                Yes! Pets are allowed on this property. Here are a few things you need to know about.
              </div>

              {isCommunityDogPark && (
                <div style={{ textAlign: 'start', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <span className={`heading bold ${classes.noWordSpacing}`} style={{ color: '#FFFFFF', textAlign: 'center' }} >COMMUNITY DOG PARK</span>
                  <div style={{ margin: '0 20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <span className={`heading bold ${classes.noWordSpacing}`} style={{ color: '#C4EEF3', textAlign: 'center' }}>YES</span>
                  </div>
                </div>
              )}

              {areDogsAllowed && (
                <div style={{ textAlign: 'start', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <span className={`heading bold ${classes.noWordSpacing}`} style={{ color: '#FFFFFF', textAlign: 'center' }} >DOGS</span>
                  <div style={{ margin: '0 20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                    {(dogDeposit || 0) > 0 && (
                      <span className={`heading bold ${classes.noWordSpacing}`} style={{ color: '#C4EEF3', textAlign: 'center' }}>
                        Deposit ${dogDeposit}
                      </span>
                    )}
                    {(dogOneTimeFee || 0) > 0 && (
                      <span className={`heading bold ${classes.noWordSpacing}`} style={{ color: '#C4EEF3', textAlign: 'center' }}>
                        One-time ${dogOneTimeFee}
                      </span>
                    )}
                    {(dogMonthlyRent || 0) > 0 && (
                      <span className={`heading bold ${classes.noWordSpacing}`} style={{ color: '#C4EEF3', textAlign: 'center' }}>
                        Monthly ${dogMonthlyRent}
                      </span>
                    )}

                    {(dogDetails || 0) == 0 && (
                      <span
                        className='paragraph'
                        style={{ color: '#FFFFFF', textAlign: 'start' }}>
                        <i>Please check with the leasing team for any additional rules or fees associated with dogs.</i>
                      </span>
                    )}                    

                  </div>
                </div>
              )}

              {areCatsAllowed && (
                <div style={{ textAlign: 'start', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <span className={`heading bold ${classes.noWordSpacing}`} style={{ color: '#FFFFFF', textAlign: 'center' }} >CATS</span>
                  <div style={{ margin: '0 20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    {(catDeposit || 0) > 0 && (
                      <span className={`heading bold ${classes.noWordSpacing}`} style={{ color: '#C4EEF3', textAlign: 'center' }}>
                        Deposit ${catDeposit}
                      </span>
                    )}
                    {(catOneTimeFee || 0) > 0 && (
                      <span className={`heading bold ${classes.noWordSpacing}`} style={{ color: '#C4EEF3', textAlign: 'center' }}>
                        One-time ${catOneTimeFee}
                      </span>
                    )}
                    {(catMonthlyRent || 0) > 0 && (
                      <span className={`heading bold ${classes.noWordSpacing}`} style={{ color: '#C4EEF3', textAlign: 'center' }}>
                        Monthly ${catMonthlyRent}
                      </span>
                    )}

                    {(catDetails || 0) == 0 && (
                      <span
                        className='paragraph'
                        style={{ color: '#FFFFFF', textAlign: 'start' }}>
                        <i>Please check with the leasing team for any additional rules or fees associated with cats.</i>
                      </span>
                    )}  

                  </div>
                </div>
              )}

            </div>
          </FromSection>
        )}


        {!arePetsAllowed && (

          <FromSection bgColor='#14BBD1'>
            <div style={{ textAlign: 'start', display: 'flex' }}>
              <span
                className={`heading bold ${classes.noWordSpacing}`}
                style={{
                  color: '#FFFFFF',
                  textAlign: 'center'
                }}
              >{`ARE MY PETS`}</span>
              <br />
              <span
                className={`heading bold ${classes.noWordSpacing}`}
                style={{
                  color: '#C4EEF3',
                  textAlign: 'center'
                }}
              >
                {'‎ ALLOWED'}
              </span>
            </div>
            <Spacer height={20} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px 0'
              }}
            >
              <div
                className='paragraph'
                style={{ color: '#FFFFFF', textAlign: 'start' }}
              >
                It is unclear at this time. Please check with the leasing agent to verify if you are able to bring your pets with you on your new home.
              </div>



            </div>
          </FromSection>
        )}




        {areActiveConcessions && (
          <FromSection bgColor='#FFFFFF'>
            <div style={{ textAlign: 'start', display: 'flex' }}>
              <span
                className={`heading bold ${classes.noWordSpacing}`}
                style={{
                  color: '#504a8a',
                  textAlign: 'center'
                }}
              >{`ADDITIONAL`}</span>
              <br />
              <span
                className={`heading bold ${classes.noWordSpacing}`}
                style={{
                  color: '#000000',
                  textAlign: 'center'
                }}
              >
                {'‎ OFFERS'}
              </span>
            </div>
            <Spacer height={20} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px 0'
              }}
            >
              <div
                className='paragraph'
                style={{ color: '#000000', textAlign: 'start' }}
              >
                Most of the time multiple offers can be combined together, please verify with the leasing office.
              </div>

              {arrayActiveConcessions.map((indConcession) => {
                return renderRow(indConcession);
              })}


            </div>
          </FromSection>

        )}



        {showThankYou ? (
          renderThankyouSection()
        ) : (
          <FromSection bgColor='#DD245C'>
            <div style={{ textAlign: 'start' }}>
              {renderMultilineColorString(landingPageTitleAction, [
                '#FFFFFF',
                '#F7C8D6'
              ])}
            </div>
            <Spacer height={30} />
            {renderReferralForm()}
          </FromSection>
        )}
        <FromSection bgColor='#14BBD1' showBottomBorder={false}>
          <div style={{ textAlign: 'start' }}>
            <span
              className={`heading bold ${classes.noWordSpacing}`}
              style={{
                color: '#FFFFFF',
                textAlign: 'center'
              }}
            >{`CONNECT WITH YOUR`}</span>
            <br />
            <span
              className={`heading bold ${classes.noWordSpacing}`}
              style={{
                color: '#C4EEF3',
                textAlign: 'center'
              }}
            >
              {'NEIGHBORS'}
            </span>
            <Spacer height={10} />
            <div
              className='paragraph'
              style={{ color: '#FFFFFF', textAlign: 'start' }}
            >
              A community app made for your apartment building. And everyone's
              welcome to join.
            </div>
            <Spacer height={10} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                className={classes.cobuAppLogo}
                src={'https://cobu-static-assets.s3.amazonaws.com/cobu-app.png'}
              />
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              gap: '15px 0',
              flexDirection: 'column',
              color: 'white',
              alignItems: 'center',
              margin: '40px 0 0 0'
            }}
          >
            <img className='footer-img' src={Logo} alt='Cobu' />
            <span
              style={{ margin: '15px 0 10px 0' }}
              className='heading4'
            >{`Program is managed by Cobu, ${referraFormlData.building.name}'s trusted community partner.`}</span>
            <a
              target='_blank'
              href='https://www.livecobu.com/privacy-policy'
              style={{ textDecoration: 'underline', color: 'white' }}
            >
              Privacy Policy
            </a>
          </div>
          <Spacer height={20} />
        </FromSection>
      </div>
      {showModal && (
        <div className={`${classes.modal} float-spinner-parent`}>
          {loading && <Spinner float />}
          <div className={classes.modalContent}>
            <div className={classes.modalForm}>
              <div className={classes.modalFormHeader}>
                <img
                  onClick={closeModal}
                  src={'https://cobu-static-assets.s3.amazonaws.com/close.png'}
                  className={classes.formCloseIcon}
                />
                <ReferralShare className={classes.shareIcon} />
              </div>
              <div className={classes.modalReferralForm}>
                {renderReferralForm(
                  '#6F6C90',
                  '#4F4F4F',
                  '#ef4a4a',
                  'black',
                  '#504a8a',
                  true
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
